export function inIFrame() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export const deepMerge = (target = {}, source) => {
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object)
      Object.assign(source[key], deepMerge(target[key], source[key]));
  }
  // Join `target` and modified `source`
  Object.assign(target || {}, source);
  return target;
};

export function getBrowserLocales() {
  return navigator.languages && navigator.languages.length
    ? navigator.languages
    : [navigator.language];
}

export function getResponseErrorMessage(response) {
  const { data } = response;
  if (!data) return response.message;
  if (data.message) return data.message;
  if (Array.isArray(data) && data.length && data[0].message) {
    return data[0].message;
  }
  return response.message;
}

export function isInsideTgMiniApp() {
  return !!window.TelegramWebviewProxy;
}
