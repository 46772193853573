const i18n = {
  'faq.count': '12',
  'faq.title': 'Часто задаваемые вопросы',
  'faq.1.question': 'Что такое МойОпрос?',
  'faq.1.answer':
    '<p>МойОпрос – платформа, на которой можно проходить на онлайн-опросы и получать за это вознаграждение. Деньги можно вывести на карту, телефон, электронный кошелек или отправить на благотворительность.</p>',
  'faq.2.question': 'Зачем регистрироваться в проекте МойОпрос?',
  'faq.2.answer':
    '<ol>\
      <li>Это простой способ немного заработать. Ты сам решаешь когда пройти опрос. Опросы несложные и в среднем длятся по 8 минут. Можно пройти даже по дороге на работу или учёбу.</li>\
      <li>Будь в числе первых, кто узнал о новом продукте или услуге. Перед запуском чего-то нового компании почти всегда проводят маркетинговое исследование. Это так здорово – сначала видишь информацию о чем-то в опросе, а потом этот же товар вживую на полке магазина.</li>\
    </ol>',
  'faq.3.question': 'Нужно ли платить за участие в проекте МойОпрос?',
  'faq.3.answer':
    '<p>Нет, участие совершенно бесплатно. Наоборот, мы будем платить тебе за ответы на опросы.</p>',
  'faq.4.question': 'Как я буду получать приглашения к опросам?',
  'faq.4.answer':
    '<p>Приглашения к опросам будут приходить через телеграм-бота, по электронной почте, а еще у нас скоро появится мобильное приложение!</p>',
  'faq.5.question': 'Сколько я буду получать за заполнение одного опроса?',
  'faq.5.answer':
    '<p>В среднем, вознаграждение за один опрос составляет 50 рублей. Когда накопишь 1000 рублей, их можно будет вывести на карту, телефон, электронный кошелек или отправить на благотворительность.</p>',
  'faq.6.question': 'Как часто мне будут приходить приглашения к опросам?',
  'faq.6.answer':
    '<p>Количество приглашений к опросам зависит профиля участника. Кому-то может приходить по 5 опросов в день, кому-то – по 3 опроса в неделю. Наши клиенты опрашивают представителей определенной целевой аудитории, например, опросы о кормах для кошек отправляются владельцам кошек и т.д. Каждый месяц мы проводим несколько сотен опросов, какие-то из них обязательно подойдут именно тебе!</p>',
  'faq.7.question': 'Почему вы задаете столько вопросов обо мне?',
  'faq.7.answer':
    '<p>Нам нужно узнать тебя немного получше, чтобы подобрать подходящие опросы. Например, к опросам об автомобильных шинах приглашаются владельцы авто, о детском питании – родители маленьких детей.</p>',
  'faq.8.question': 'Я зарегистрировался в проекте. Когда мне начнут приходить опросы?',
  'faq.8.answer':
    '<p>Это зависит от твоей профильной анкеты. Мы рассылаем приглашения к опросам исходя из профилей участников. Например, владельцы кошек, родители детей от 1 до 3 лет, геймеры и т.д.  Каждый месяц мы проводим несколько сотен опросов, среди них обязательно найдутся подходящие для тебя!</p>',
  'faq.9.question': 'У меня произошла проблема при прохождении опроса, что мне делать?',
  'faq.9.answer':
    '<p>Неприятно, что такое произошло! Пожалуйста, попробуй перейти к опросу с другого браузера или устройства. Если это не поможет, напиши в службу поддержки support@moiopros.ru, указав номер опроса, и опиши суть проблемы, по возможности приложи скриншот.</b></p>',
  'faq.10.question': 'С каких устройств я могу проходить опросы?',
  'faq.10.answer':
    '<p>Почти все наши опросы можно заполнить с любых устройств (смартфон, планшет, компьютер). Изредка у нас бывают опросы, которые по техническим причинам можно пройти только с компьютера, тогда об этом будет сказано в приглашении.</p>',
  'faq.11.question': 'Я заполнил опрос, но вознаграждение не было начислено. Что мне делать?',
  'faq.11.answer':
    '<p>В 99% случаев вознаграждение за опрос начисляется автоматически. Но иногда бывает, что не произошло перенаправления на страницу начисления вознаграждения. В этом случае мы начисляем вознаграждение вручную, когда получаем от клиента списки всех заполнивших опрос. Это может занять до пары недель, но не волнуйся, вознаграждение обязательно будет начислено. Если остались вопросы, напиши нам по адресу support@moiopros.ru</p>',
  'faq.12.question': 'Есть ли срок действия у баллов в проекте?',
  'faq.12.answer':
    '<p>Если ты участвуешь в опросах, то вознаграждение не может никуда деться. Если ты полгода не отвечал на приглашения, мы отправим тебе письмо с вопросом, хочешь ли ты продолжать участвовать. Если ты на него не ответишь, то участие в проекте будет прекращено, а баллы – списаны.</p>',

  'dialog.login.title': 'Вход в аккаунт',
  'dialog.login.button__ok': 'Одноклассники',
  'dialog.login.button__mailru': 'Mail.ru',
  'dialog.login.button__yandex': 'Яндекс',
  'dialog.login.button__vk': 'ВКонтакте',
  'dialog.login.divider__or_via_email': '',
  'dialog.login.placeholder__email': 'Email',
  'dialog.login.placeholder__password': 'Пароль',
  'dialog.login.link__forgot_password_q': 'Забыли пароль?',
  'dialog.login.text__dont_have_an_account_q': 'Еще не зарегистрированы?',
  'dialog.login.link__create_account': 'Регистрация',
  'dialog.login.button__login': 'Вход',
  'dialog.login.error_message__invalid_login_password': 'Неправильный логин или пароль',
  'dialog.login.error_message__unknown_error': 'Что-то пошло не так. Попробуйте позже',
  'dialog.login.error_message__inactive_member': 'Неактивная учетная запись',

  'dialog.contact_us.title': 'Напишите нам',
  'dialog.contact_us.description': ' ',
  'dialog.contact_us.placeholder__your_email': 'Email*',
  'dialog.contact_us.placeholder__your_question': 'Сообщение',
  'dialog.contact_us.button__send_a_message': 'Отправить',
  'dialog.contact_us.notification__success': 'Спасибо, мы свяжемся с вами в ближайшее время',
  'dialog.contact_us.error_message__unknown_error': 'Что-то пошло не так.',

  'dialog.thanks.title': 'Спасибо!',
  'dialog.thanks.description': 'Мы свяжемся с вами в ближайшее время',
  'dialog.thanks.button__ok': 'Готово',
};

export default i18n;
